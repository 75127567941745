import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Progress from "../components/molecules/Placeholders/LoadingPage";

const Guide = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/style-guide")), 0);
  });
});

const School = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./School")), 0);
  });
});

const Application = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./Application")), 0);
  });
});

const Admin = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./Admin")), 0);
  });
});

const Portal = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./Portal")), 0);
  });
});

const Routes = () => {
  return (
    <Router>
      <Suspense fallback={<Progress />}>
        <Switch>
          <Route path="/styles" component={Guide} />
          <Route path="/school" component={School} />
          <Route path="/application" component={Application} />
          <Route path="/portal" component={Portal} />
          <Route path="/admin" component={Admin} />
          <Route path="/" component={Progress} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
