import React from "react";
import styled from "styled-components";

const Loading = styled.div`
  display: flex;
  align-items: center;
  --fill-color: var(--color-primary-blue-4);
  --bg-color: var(--color-primary-blue-6);
  .loader {
    display: block;
    position: relative;
    height: 20px;
    width: 140px;
    background-image: linear-gradient(#fff 20px, transparent 0),
      linear-gradient(#fff 20px, transparent 0),
      linear-gradient(#fff 20px, transparent 0),
      linear-gradient(#fff 20px, transparent 0);
    background-repeat: no-repeat;
    background-size: 20px auto;
    background-position: 0 0, 40px 0, 80px 0, 120px 0;
    animation: pgfill 1s linear infinite;
  }

  @keyframes pgfill {
    0% {
      background-image: linear-gradient(var(--bg-color) 20px, transparent 0),
        linear-gradient(var(--bg-color) 20px, transparent 0),
        linear-gradient(var(--bg-color) 20px, transparent 0),
        linear-gradient(var(--bg-color) 20px, transparent 0);
    }
    25% {
      background-image: linear-gradient(var(--fill-color) 20px, transparent 0),
        linear-gradient(var(--bg-color) 20px, transparent 0),
        linear-gradient(var(--bg-color) 20px, transparent 0),
        linear-gradient(var(--bg-color) 20px, transparent 0);
    }
    50% {
      background-image: linear-gradient(var(--fill-color) 20px, transparent 0),
        linear-gradient(var(--fill-color) 20px, transparent 0),
        linear-gradient(var(--bg-color) 20px, transparent 0),
        linear-gradient(var(--bg-color) 20px, transparent 0);
    }
    75% {
      background-image: linear-gradient(var(--fill-color) 20px, transparent 0),
        linear-gradient(var(--fill-color) 20px, transparent 0),
        linear-gradient(var(--fill-color) 20px, transparent 0),
        linear-gradient(var(--bg-color) 20px, transparent 0);
    }
    100% {
      background-image: linear-gradient(var(--fill-color) 20px, transparent 0),
        linear-gradient(var(--fill-color) 20px, transparent 0),
        linear-gradient(var(--fill-color) 20px, transparent 0),
        linear-gradient(var(--fill-color) 20px, transparent 0);
    }
  }
`;

const Loader = () => {
  return (
    <Loading>
      <div className="loader" />
    </Loading>
  );
};

export default Loader;
