import React from "react";
import styled from "styled-components";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Loading from "../Loaders/Cubes";

const useStyles = makeStyles((theme) => {
  return createStyles({
    bar: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  });
});

const Main = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
`;

const Progress = () => {
  const classes = useStyles();
  return (
    <div className="app-wrapper">
      <div className={classes.bar}>
        <LinearProgress />
      </div>
      <Main>
        <Loading />
      </Main>
      <div />
    </div>
  );
};

export default Progress;
